<template>
  <div>
    <el-tabs v-model="tab">
      <el-tab-pane
        label="單位管理"
        name="unitTab"
        v-if="isShowUnitTab">
        <TheAdminUnitTab>
        </TheAdminUnitTab>
      </el-tab-pane>
      <el-tab-pane
        label="使用者管理"
        name="userTab"
        v-if="isShowUserTab">
        <TheAdminUserTab>
        </TheAdminUserTab>
      </el-tab-pane>
      <el-tab-pane
        label="使用者啟用"
        name="userActiveTab"
        v-if="isShowUserActiveTab">
        <span slot="label" style="position:relative">
          使用者啟用
          <el-badge
            class="item"
            type="danger"
            :value="appliedUsersCount"
            v-if="appliedUsersCount">
          </el-badge>
        </span>
        <TheAdminUserActiveTab>
        </TheAdminUserActiveTab>
      </el-tab-pane>
      <!-- <el-tab-pane
        label="使用者權限"
        name="userLimitTab"
        v-if="isShowUserLimitTab">
        <TheAdminUserLimitTab>
        </TheAdminUserLimitTab>
      </el-tab-pane> -->
    </el-tabs>
  </div>
</template>

<script>
import routerService from '@/utils/routerService.js'
import { mapState, mapGetters } from 'vuex'
// import TheAdminUserLimitTab from '@/components/TheAdminUserLimitTab/TheAdminUserLimitTab.vue'
import TheAdminUnitTab from '@/components/TheAdminUnitTab/TheAdminUnitTab.vue'
import TheAdminUserTab from '@/components/TheAdminUserTab/TheAdminUserTab.vue'
import TheAdminUserActiveTab from '@/components/TheAdminUserActiveTab/TheAdminUserActiveTab.vue'

export default {
  name: 'admin',
  components: {
    // TheAdminUserLimitTab,
    TheAdminUnitTab,
    TheAdminUserTab,
    TheAdminUserActiveTab
  },
  data () {
    return {
      tab: 'unitTab',
    }
  },
  computed: {
    ...mapState([
      'userLimits'
    ]),
    ...mapGetters([
      'getterIsManager',
      'getterAppliedUsers'
    ]),
    isShowUnitTab () {
      if (this.userLimits['A']) {
        return true
      } else {
        return false
      }
    },
    isShowUserTab () {
      if (this.userLimits['B-1'] ||
      this.userLimits['B-2'] ||
      this.userLimits['B-3'] ||
      this.userLimits['B-4'] ||
      this.userLimits['B-5'] ||
      this.userLimits['B-6']) {
        return true
      } else {
        return false
      }
    },
    isShowUserActiveTab () {
      if (this.userLimits['B-7'] ||
      this.userLimits['B-8'] ||
      this.userLimits['B-9']) {
        return true
      } else {
        return false
      }
    },
    isShowUserLimitTab () {
      if (this.userLimits['C-1'] ||
      this.userLimits['C-2']) {
        return true
      } else {
        return false
      }
    },
    appliedUsersCount () {
      return this.getterAppliedUsers.length
    }
  },
  created () {
    // 無權限則回首頁
    if (this.userLimits == null || this.getterIsManager === false) {
      // this.$router.replace({ name: 'home' })
      routerService('replace', { name: 'home' })
    }
  }
}
</script>
