<template>
  <div>
    <div class="table-set">
      <div class="table-toolbar">
        <div class="toolbar-left">
          <el-select v-model="tableToolbarRoleSelect" placeholder="所有使用者身份" size="mini" style="margin-right:10px">
            <el-option
              v-for="item in tableToolbarRoleOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <el-select v-model="tableToolbarUnitSelect" placeholder="所有使用者所屬單位" size="mini">
            <el-option
              v-for="item in tableToolbarUnitOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="toolbar-right">
          <el-button
            type="primary"
            size="mini"
            :disabled="isImportButtonDisabled"
            @click="dialogAddMultiple = true">
            <i class="fas fa-plus"></i>批次匯入
          </el-button>
          <el-button
            type="primary"
            size="mini"            
            @click="dialogAddSingle = true">
            <i class="fas fa-plus"></i>手動新增
          </el-button>
        </div>
      </div>
      <el-table
        :data="currentTableData"
        empty-text="目前暫無資料喔 :)"
        highlight-current-row
        stripe>
        <el-table-column
          type="expand">
          <template slot-scope="scope">
            <!-- 顯示資料 -->
            <div
              class="table table--fixed table--light"
              v-if="isOpenEditBlock(scope.$index)==false">
              <!-- <div class="table-row">
                <div class="table-cell label">使用者姓名：</div>
                <div class="table-cell value">{{scope.row.name}}</div>
              </div> -->
              <div class="table-row">
                <div class="table-cell label">帳戶角色：</div>
                <div class="table-cell value">{{scope.row.role}}</div>
              </div>
              <div class="table-row">
                <div class="table-cell label">email：</div>
                <div class="table-cell value">{{scope.row.email}}</div>
              </div>
              <div class="table-row">
                <div class="table-cell label">單位名稱：</div>
                <div class="table-cell value">{{scope.row.unit}}</div>
              </div>
              <div class="table-row">
                <div class="table-cell label">上次登入時間：</div>
                <div class="table-cell value">{{scope.row.login_time}}</div>
              </div>
              <div class="table-row">
                <div class="table-cell label">帳號啟用時間：</div>
                <div class="table-cell value">{{scope.row.active_time}}</div>
              </div>
              <div class="table-row">
                <div class="table-cell label">帳號註冊時間：</div>
                <div class="table-cell value">{{scope.row.regist_time}}</div>
              </div>
              <div class="table-row">
                <div class="table-cell label">帳號停權設定：</div>
                <div class="table-cell value">{{scope.row.status}}</div>
              </div>
            </div>
            <!-- 編輯資料 -->
            <el-form
              ref="editingForm"
              label-width="160px"
              label-position="left"
              v-if="isOpenEditBlock(scope.$index)"
              :model="editingForm"
              :rules="editingRules">
              <!-- <el-form-item label="使用者姓名" prop="name">
                <el-input
                  placeholder="請輸入"
                  :disabled="isUserEditDisabled"
                  v-model="editingForm.name">
                </el-input>
              </el-form-item> -->
              <el-form-item label="帳戶角色" prop="role">
                <el-select
                  placeholder="請選擇"
                  style="width:100%"
                  :disabled="isUserEditDisabled"
                  v-model="editingForm.role">
                  <el-option
                    v-for="item in tableToolbarRoleOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="email" prop="email">
                <el-input
                  placeholder="請輸入"
                  :disabled="isUserEditDisabled"
                  v-model="editingForm.email"></el-input>
              </el-form-item>
              <el-form-item label="單位名稱" prop="unit">
                <el-select
                  placeholder="請選擇"
                  style="width:100%"
                  :disabled="isUserEditDisabled"
                  v-model="editingForm.unit">
                  <el-option
                    v-for="item in getterUnitOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="上次登入時間">
                {{scope.row.login_time}}
              </el-form-item>
              <el-form-item label="帳號啟用時間">
                {{scope.row.active_time}}
              </el-form-item>
              <el-form-item label="帳號註冊時間">
                {{scope.row.regist_time}}
              </el-form-item>
              <el-form-item label="帳號停權設定">
                <el-switch
                  active-text="啟用"
                  inactive-text="停權"
                  active-value="啟用"
                  inactive-value="停權"
                  :disabled="returnIsSwitchDisabled(editingForm.status)"
                  v-model="editingForm.status">
                </el-switch>
              </el-form-item>
            </el-form>
            <div class="table-toolbar toolbar-bottom">
              <div class="toolbar-left" v-if="isOpenEditBlock(scope.$index)==false">
                <el-button
                  type="primary"
                  size="mini"
                  :loading="isEditLoading"
                  @click="openEditBlock(scope.$index)">
                  <font-awesome-icon :icon="['fas', 'pen']"/>
                  編輯
                </el-button>
              </div>
              <div class="toolbar-left" v-if="isOpenEditBlock(scope.$index)">
                <el-button
                  type="primary"
                  size="mini"
                  :loading="isEditLoading"
                  @click="saveEditingItem(scope.$index)">
                  儲存
                </el-button>
                <el-button
                  type="default"
                  size="mini"
                  @click="cancelEditBlock">
                  取消
                </el-button>
                <el-button
                  type="danger"
                  size="mini"
                  :disabled="isDeleteButtonDisabled"
                  :loading="isDeleteLoading"
                  @click="deleteItem(scope.$index)">
                  <i class="fas fa-trash-alt"></i>刪除使用者
                </el-button>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="序號"
          type="index"
          align="center"
          width="56">
        </el-table-column>
        <!-- <el-table-column
          label="姓名"
          prop="name"
          width="160">
        </el-table-column> -->
        <el-table-column
          label="所屬單位"
          prop="unit"
          width="160">
        </el-table-column>
        <el-table-column
          label="使用者身份"
          prop="role"
          width="160">
        </el-table-column>
        <el-table-column
          label="使用者電子郵件"
          prop="email">
        </el-table-column>
        <el-table-column
          label="狀態"
          prop="status"
          width="70">
        </el-table-column>
      </el-table>
    </div>
    <div class="align-right">
      <el-pagination
        layout="prev, pager, next"
        :total="filteredTableData.length"
        :page-size="pageSize"
        :current-page.sync="currentPage">
      </el-pagination>
    </div>
    <!-- 批次匯入 -->
    <el-dialog
      title="批次匯入"
      :visible.sync="dialogAddMultiple"
      width="75vw"
      :close-on-click-modal="false"
      append-to-body>
      <el-form
        ref="importingForm"
        :model="importingForm"
        :rules="importingRules">
        <el-form-item prop="import">
          <el-upload
            :accept="acceptFileTypes"
            :file-list="importingForm._fileList"
            :on-remove="handleRemove"
            :before-upload="beforeUpload">
            <el-button size="small" type="primary">選擇檔案</el-button>
            <div slot="tip" class="el-upload__tip">
              <ul>
                <li>點此下載「<a href="/files/使用者管理_批次上傳格式.xlsx">批次匯入格式及範例說明」</a></li>
                <li>上傳文件僅限於.xls及.xlsx檔</li>
              </ul>
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="align-center">
        <el-button
          @click="cancelImportingLightbox">
          取消
        </el-button>
        <el-button
          type="primary"
          :loading="isImportLoading"
          @click="saveImportingForm">
          匯入
        </el-button>
      </div>
    </el-dialog>
    <!-- 手動新增 -->
    <el-dialog
      title="新增使用者"
      :visible.sync="dialogAddSingle"
      width="75vw"
      :close-on-click-modal="false"
      append-to-body>
      <el-form
        ref="addingForm"
        label-width="160px"
        label-position="left"
        :model="addingForm"
        :rules="addingRules">
        <!-- <el-form-item label="使用者姓名" prop="name">
          <el-input placeholder="請輸入" v-model="addingForm.name"></el-input>
        </el-form-item> -->
        <el-form-item label="帳戶角色" prop="role">
          <el-select v-model="addingForm.role" placeholder="請選擇" style="width:100%">
            <el-option
              v-for="item in addRoleOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="email" prop="email">
          <el-input placeholder="請輸入" v-model="addingForm.email"></el-input>
        </el-form-item>
        <el-form-item label="單位名稱" prop="unit">
          <el-select v-model="addingForm.unit" placeholder="請選擇" style="width:100%">
            <el-option
              v-for="item in getterUnitOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="align-center">
        <el-button
          @click="cancelAddingLightbox">
          取消
        </el-button>
        <el-button
          type="primary"
          :loading="isAddLoading"
          @click="saveAddingForm">
          確定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as apis from '@/apis/index.ts'
import { mapActions, mapState, mapGetters } from 'vuex'
import cityOptions from '@/const/cityOptions.js'

export default {
  props: {
    // tableData: {
    //   type: Array,
    //   default () {
    //     return []
    //   }
    // }
  },
  data () {
    return {
      cityOptions,
      acceptFileTypes: '.xls,.xlsx',
      isEditLoading: false,
      isDeleteLoading: false,
      isImportLoading: false,
      isAddLoading: false,
      // tableData: [],
      tableToolbarRoleSelect: '',
      tableToolbarUnitSelect: '',
      pageSize: 10,
      currentPage: 1,
      editingForm: {
        // 'name': '', // 使用者姓名
        'role': '', // 角色
        'email': '',
        'unit': '', // 單位名稱
        'login_time': '', // 上次登入時間
        'active_time': '', // 帳號啟用時間
        'regist_time': '', // 帳號註冊時間
        'status': true,
        _originStatus: true // 編輯前的狀態
      },
      addingForm: {
        // 'name': '', // 使用者姓名
        'role': '', // 角色
        'email': '',
        'unit': '', // 單位名稱
        'login_time': '', // 上次登入時間
        'active_time': '', // 帳號啟用時間
        'regist_time': '', // 帳號註冊時間
        'status': true // 帳號是否啟用
      },
      importingForm: {
        import: null,
        _fileList: []
      },
      editingRules: {
        // name: [
        //   { required: true, message: '必填', trigger: 'blur' }
        // ],
        role: [
          { required: true, message: '必填', trigger: 'change' }
        ],
        email: [
          { required: true, message: '必填', trigger: 'blur' },
          { type: 'email', message: '請輸入電子郵件正確格式', trigger: ['blur', 'change'] }
        ],
        unit: [
          { required: true, message: '必填', trigger: 'change' }
        ]
      },
      addingRules: {
        // name: [
        //   { required: true, message: '必填', trigger: 'blur' }
        // ],
        role: [
          { required: true, message: '必填', trigger: 'change' }
        ],
        email: [
          { required: true, message: '必填', trigger: 'blur' },
          { type: 'email', message: '請輸入電子郵件正確格式', trigger: ['blur', 'change'] }
        ],
        unit: [
          { required: true, message: '必填', trigger: 'change' }
        ]
      },
      importingRules: {
        import: [
          { required: true, message: '尚未選擇上傳文件', trigger: 'change' }
        ]
      },
      dialogAddSingle: false,
      dialogAddMultiple: false,
      editingIndex: -1, // 編輯中的資料索引（非編輯中則為-1）
    }
  },
  computed: {
    ...mapGetters([
      'getterRegisteredUsers',
      'getterUnitOptions',
      'getterRoleOptions',
      'getterLimitB1',
      'getterLimitB2',
      'getterLimitB3',
      'getterLimitB4',
      'getterLimitB5',
      'getterLimitB6',
    ]),
    ...mapState([
      // 'users',
      'userInfo'
    ]),
    tableToolbarRoleOptions () {
      const defaultOptions = [{
        label: '所有使用者身份',
        value: ''
      }]
      return defaultOptions.concat(this.getterRoleOptions)
    },
    tableToolbarUnitOptions () {
      const defaultOptions = [{
        label: '所有使用者所屬單位',
        value: ''
      }]
      return defaultOptions.concat(this.getterUnitOptions)
    },
    // 手動新增使用者的角色選單
    addRoleOptions () {
      let addRoleOptions = this.getterRoleOptions.map(d => d)
      // 無「手動新增最高管理者」權限
      if (this.getterLimitB1 === false) {
        addRoleOptions.map(d => {
          if (d === '最高管理者') {
            d.disabled = true
          }
          return d
        })
      }
      // 無「手動新增單位管理者」權限
      if (this.getterLimitB2 === false) {
        addRoleOptions.map(d => {
          if (d === '單位管理者') {
            d.disabled = true
          }
          return d
        })
      }
      return addRoleOptions
    },
    // 匯入按鈕是否disabled
    isImportButtonDisabled () {
      if (this.getterLimitB3) {
        return false
      } else {
        return true
      }
    },
    // 刪除按鈕是否disabled
    isDeleteButtonDisabled () {
      if (this.getterLimitB6) {
        return false
      } else {
        return true
      }
    },
    tableData () {
      // 已註冊及停權的使用者
      return this.getterRegisteredUsers
    },
    filteredTableData () {
      if (!this.tableToolbarRoleSelect && !this.tableToolbarUnitSelect) {
        return this.tableData
      }
      return this.tableData.filter(d => {
        // 任一條件不符合即被篩選過濾掉
        let result = true
        if (this.tableToolbarRoleSelect && d.role != this.tableToolbarRoleSelect) {
          result = false
        }
        if (this.tableToolbarUnitSelect && d.unit != this.tableToolbarUnitSelect) {
          result = false
        }
        return result
      })
    },
    currentTableData () {
      // 分頁
      let startNo = (this.currentPage - 1) * this.pageSize
      return this.filteredTableData.slice(startNo, startNo + this.pageSize)
    },
    // 停權狀態禁止編輯使用者資料
    isUserEditDisabled () {
      if (this.editingForm.status == '啟用') {
        return false
      } else {
        return true
      }
    }
  },
  methods: {
    ...mapActions([
      'actionInitData'
    ]),
    returnIsSwitchDisabled (value) {
      // 無「使用者停權」的權限
      if (value === true && this.getterLimitB4 === false) {
        return true
      }
      // 無「使用者啟用」的權限
      if (value === false && this.getterLimitB5 === false) {
        return true
      }
      return false
    },
    // tableRowClassName ({row, rowIndex}) {
    //   if (row.status === '停權') {
    //     console.log('warning-row')
    //     return 'warning-row'
    //   } else {
    //     return ''
    //   }
    // },
    cancelEditBlock () {
      // this.editingForm = {}
      this.editingIndex = -1
    },
    openEditBlock (index) {
      // 表單資料
      this.editingForm = JSON.parse(JSON.stringify(this.currentTableData[index]))
      this.editingForm._originStatus = this.editingForm.status
      // 展開的表單編號
      this.editingIndex = index
    },
    isOpenEditBlock (index) {
      if (index === this.editingIndex) {
        return true
      } else {
        return false
      }
    },
    async saveEditingItem (index) {
      if (this.editingForm._originStatus == '停權' && this.editingForm.status == '啟用') {
        // -- case 停權帳號啟用 --
        await apis.userReactive({
          email: this.editingForm.email
        })
      }
      if (this.editingForm.status == '啟用') {
        // -- case 修改資料 --
        this.$refs.editingForm.validate(async (valid) => {
          if (!valid) {
            return
          }
          this.isEditLoading = true
          apis.userEdit({
            'email': this.editingForm.email, // 信箱
            // 'name': this.editingForm.name, // 姓名
            'phone': this.editingForm.phone,
            'note': this.editingForm.note,
            'role': this.editingForm.role,
            'unit': this.editingForm.unit, // 單位名稱
          }).then(d => {
              this.resetForm()
            })
            .catch(e => {
              this.isEditLoading = false
            })
        })
      } else {
        // -- case 停權（停權帳號不能修改資料） --
        apis.userForbid({
            'email': this.editingForm.email // 信箱
          }).then(d => {
              this.resetForm()
            })
            .catch(e => {
              this.isEditLoading = false
            })
      }
    },
    deleteItem (index) {
      this.$confirm('此操作將永久刪除該使用者，是否繼續？', '提示', {
        confirmButtonText: '確定刪除',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(data => {
        this.isDeleteLoading = false
        this.cancelEditBlock()
        apis.userDelete({
          email: this.editingForm.email
        }).then(d => {
          this.resetForm()
        }).catch(e => {
          this.isDeleteLoading = false
        })
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消刪除'
        // })
      })
    },
    cancelAddingLightbox () {
      this.dialogAddSingle = false
    },
    saveAddingForm () {
      this.$refs.addingForm.validate(async (valid) => {
        if (!valid) {
          return
        }
        this.isAddLoading = true
        apis.userAdd(this.addingForm)
          .then(data => {
            this.resetForm()
          })
          .catch(e => {
            this.isAddLoading = false
          })
      })
    },
    cancelImportingLightbox () {
      this.dialogAddMultiple = false
    },
    beforeUpload (file) {
      // const isLt2M = file.size / 1024 / 1024 < 10;
      // if (!isLt2M) {
      //   this.$message.error('檔案大小不可超過 10MB!');
      // }
      if (1 === 1) {
        // console.log('file')
        // console.log(file)
        this.$set(this.importingForm, 'import', file)
        this.$set(this.importingForm, '_fileList', [{ name: file.name }])
      } else {
        this.$set(this.importingForm, 'import', null)
        this.$set(this.importingForm, '_fileList', [])
        // this.$message.error('檔案大小不可超過 10MB!');
      }
      this.$refs.importingForm.validate()
    },
    handleRemove () {
      this.$set(this.importingForm, 'import', null)
      this.$set(this.importingForm, '_fileList', [])
      this.$refs.importingForm.validate()
    },
    saveImportingForm () {
      this.$refs.importingForm.validate((valid) => {
        if (!valid) {
          return
        }
        // formData
        let formData = new FormData()
        formData.append('import', this.importingForm.import)
        this.isImportLoading = true
        apis.userImport(formData)
          .then(data => {
            this.resetForm()
          }).catch(e => {
            this.isImportLoading = false
          })
      })
    },
    async resetForm () {
      // 重新取得資料
      await this.actionInitData()
      // 重設畫面
      this.isEditLoading = false
      this.isDeleteLoading = false
      this.isImportLoading = false
      this.isAddLoading = false
      this.dialogAddMultiple = false
    }
  },
  created () {
    // apis.usersGet({
    //   'company_sno': this.userInfo.company_sno
    // })
    //   .then(d => {
    //     this.tableData = d.payload || []
    //   })
  },
  watch: {
    currentTableData () {
      this.editingIndex = -1
    }
  }
}
</script>

<style lang="scss" scoped>
  // .el-table tr.warning-row {
  //   background: oldlace !important;
  // }
</style>
