<template>
  <div>
    <div class="table-set">
      <div class="table-toolbar">
        <div class="toolbar-left">
          <el-select v-model="tableToolbarSelect" placeholder="所有單位列表" size="mini">
            <el-option
              v-for="item in tableToolbarSelectOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="toolbar-right">
          <el-button
            type="primary"
            size="mini"
            @click="dialogAddMultiple = true">
            <i class="fas fa-plus"></i>批次匯入
          </el-button>
          <el-button
            type="primary"
            size="mini"
            @click="dialogAddSingle = true">
            <i class="fas fa-plus"></i>手動新增
          </el-button>
        </div>
      </div>
      <el-table
        :data="currentTableData"
        empty-text="目前暫無資料喔 :)"
        highlight-current-row
        stripe>
        <el-table-column
          type="expand">
          <template slot-scope="scope">
            <!-- 顯示資料 -->
            <div
              class="table table--fixed table--light"
              v-if="isOpenEditBlock(scope.$index)==false">
              <div class="table-row">
                <div class="table-cell label">單位名稱：</div>
                <div class="table-cell value">{{scope.row.unit}}</div>
              </div>
              <!-- <div class="table-row">
                <div class="table-cell label">單位電話：</div>
                <div class="table-cell value">{{scope.row.phone}}</div>
              </div> -->
              <div class="table-row">
                <div class="table-cell label">單位所在縣市：</div>
                <div class="table-cell value">{{scope.row.county}}</div>
              </div>
              <div class="table-row">
                <div class="table-cell label">單位地址：</div>
                <div class="table-cell value">{{scope.row.address}}</div>
              </div>
              <div class="table-row">
                <div class="table-cell label">單位管理者：</div>
                <!-- <div class="table-cell value">{{scope.row.unit_managers.join('、')}}</div> -->
                <div class="table-cell value">{{showManagersName(scope.row.managers)}}</div>
              </div>
              <div class="table-row">
                <div class="table-cell label">單位聯絡人：</div>
                <div class="table-cell value">{{scope.row.contact}}</div>
              </div>
              <div class="table-row">
                <div class="table-cell label">聯絡人電話：</div>
                <div class="table-cell value">{{scope.row.phone}}</div>
              </div>
              <div class="table-row">
                <div class="table-cell label">聯絡人信箱：</div>
                <div class="table-cell value">{{scope.row.email}}</div>
              </div>
              <div class="table-row">
                <div class="table-cell label">單位使用人數：</div>
                <!-- <div class="table-cell value">{{scope.row.user_count}}人</div> -->
                <div class="table-cell value">{{scope.row.account_count}}</div>
              </div>
              <div class="table-row">
                <div class="table-cell label">單位使用上限：</div>
                <!-- <div class="table-cell value">{{scope.row.user_count_limit}}人</div> -->
                <div class="table-cell value">{{scope.row.account_limit}}</div>
              </div>
            </div>
            <!-- 編輯資料 -->
            <el-form
              ref="editingForm"
              label-width="160px"
              label-position="left"
              v-if="isOpenEditBlock(scope.$index)"
              :model="editingForm"
              :rules="editingRules">
              <el-form-item label="單位名稱" prop="new_unit">
                <el-input placeholder="請輸入" v-model="editingForm.new_unit"></el-input>
              </el-form-item>
              <!-- <el-form-item label="單位電話">
                <el-input placeholder="請輸入" v-model="editingForm.phone"></el-input>
              </el-form-item> -->
              <el-form-item label="單位所在縣市" prop="county">
                <el-select v-model="editingForm.county" placeholder="請選擇" style="width:100%">
                  <el-option
                    v-for="item in cityOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="單位地址" prop="address">
                <el-input placeholder="請輸入" v-model="editingForm.address"></el-input>
              </el-form-item>
              <el-form-item label="單位管理者">
                <!-- <el-input placeholder="請輸入" v-model="editingForm.unit_managers"></el-input> -->
                <el-select
                  style="width:100%"
                  v-model="editingForm.managers"
                  filterable
                  multiple
                  placeholder="請選擇">
                  <el-option
                    v-for="item in editingManagerOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    <span style="float: left">{{ item.data.name }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.data.email }}</span>
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="單位聯絡人" prop="contact">
                <el-input placeholder="請輸入" v-model="editingForm.contact"></el-input>
                <!-- <el-select v-model="editingForm.contact" filterable placeholder="請選擇">
                  <el-option
                    v-for="item in getterActivedUsers"
                    :key="item.name"
                    :label="item.name"
                    :value="item.name">
                  </el-option>
                </el-select> -->
              </el-form-item>
              <el-form-item label="聯絡人電話" prop="phone">
                <el-input placeholder="請輸入" v-model="editingForm.phone"></el-input>
              </el-form-item>
              <el-form-item label="聯絡人信箱" prop="email">
                <el-input placeholder="請輸入" v-model="editingForm.email"></el-input>
              </el-form-item>
              <el-form-item label="單位使用人數" prop="account_count">
                {{editingForm.account_count}}                
              </el-form-item>
              <el-form-item label="單位使用上限" prop="account_limit">
                <el-input placeholder="請輸入" v-model.number="editingForm.account_limit"></el-input>
              </el-form-item>
            </el-form>
            <div class="table-toolbar toolbar-bottom">
              <div class="toolbar-left" v-if="isOpenEditBlock(scope.$index)==false">
                <el-button
                  type="primary"
                  size="mini"
                  @click="openEditBlock(scope.$index)">
                  <font-awesome-icon :icon="['fas', 'pen']"/>編輯
                </el-button>
              </div>
              <div class="toolbar-left" v-if="isOpenEditBlock(scope.$index)">
                <el-button
                  type="primary"
                  size="mini"
                  :loading="isEditLoading"
                  @click="saveEditingItem(scope.$index)">
                  儲存
                </el-button>
                <el-button
                  type="default"
                  size="mini"
                  @click="cancelEditBlock">
                  取消
                </el-button>
                <el-button
                  type="danger"
                  size="mini"
                  :loading="isDeleteLoading"
                  @click="deleteItem(scope.$index)">
                  <i class="fas fa-trash-alt"></i>刪除單位
                </el-button>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="序號"
          prop="sno"
          align="center"
          width="56">
        </el-table-column>
        <el-table-column
          label="所屬單位"
          prop="unit"
          width="160">
        </el-table-column>
        <el-table-column
          label="單位聯絡電話"
          prop="phone"
          width="160">
        </el-table-column>
        <el-table-column
          label="單位地址"
          prop="address">
        </el-table-column>
      </el-table>
    </div>
    <div class="align-right">
      <el-pagination
        layout="prev, pager, next"
        :total="filteredTableData.length"
        :page-size="pageSize"
        :current-page.sync="currentPage">
      </el-pagination>
    </div>
    <!-- 批次匯入 -->
    <el-dialog
      title="批次匯入"
      width="75vw"
      append-to-body
      :visible.sync="dialogAddMultiple"
      :close-on-click-modal="false">
      <el-form
        ref="importingForm"
        :model="importingForm"
        :rules="importingRules">
        <el-form-item prop="import">
          <el-upload
            :accept="acceptFileTypes"
            :file-list="importingForm._fileList"
            :on-remove="handleRemove"
            :before-upload="beforeUpload">
            <el-button size="small" type="primary">選擇檔案</el-button>
            <div slot="tip" class="el-upload__tip">
              <ul>
                <li>點此下載「<a href="/files/單位管理_批次上傳格式.xlsx">批次匯入格式及範例說明」</a></li>
                <li>上傳文件僅限於.xls及.xlsx檔</li>
              </ul>
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="align-center">
        <el-button
          @click="cancelImportingLightbox">
          取消
        </el-button>
        <el-button
          type="primary"
          :loading="isImportLoading"
          @click="saveImportingForm">
          匯入
        </el-button>
      </div>
    </el-dialog>
    <!-- 手動新增 -->
    <el-dialog
      title="新增單位"
      :visible.sync="dialogAddSingle"
      width="75vw"
      :close-on-click-modal="false"
      append-to-body>
      <el-form
        ref="addingForm"
        label-width="160px"
        label-position="left"
        :model="addingForm"
        :rules="addingRules">
        <el-form-item label="單位名稱" prop="unit">
          <el-input placeholder="請輸入" v-model="addingForm.unit"></el-input>
        </el-form-item>
        <!-- <el-form-item label="單位電話" prop="phone">
          <el-input placeholder="請輸入" v-model="addingForm.phone"></el-input>
        </el-form-item> -->
        <el-form-item label="單位所在縣市" prop="county">
          <el-select v-model="addingForm.county" placeholder="請選擇" style="width:100%">
            <el-option
              v-for="item in cityOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="單位地址" prop="address">
          <el-input placeholder="請輸入" v-model="addingForm.address"></el-input>
        </el-form-item>
        <el-form-item label="單位管理者" prop="managers">
          <el-select
            style="width:100%"
            v-model="addingForm.managers"
            filterable
            multiple
            placeholder="請選擇">
            <el-option
              v-for="item in addingManagerOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
              <span style="float: left">{{ item.data.name }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.data.email }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="單位聯絡人" prop="contact">
          <!-- <el-input placeholder="請輸入" v-model="addingForm.contact"></el-input> -->
          <el-input placeholder="請輸入" v-model="addingForm.contact"></el-input>
        </el-form-item>
        <el-form-item label="聯絡人電話" prop="phone">
          <el-input placeholder="請輸入" v-model="addingForm.phone"></el-input>
        </el-form-item>
        <el-form-item label="聯絡人信箱" prop="email">
          <el-input placeholder="請輸入" v-model="addingForm.email"></el-input>
        </el-form-item>
        <el-form-item label="單位使用上限" prop="account_limit">
          <el-input placeholder="請輸入" v-model.number="addingForm.account_limit"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="align-center">
        <el-button
          @click="cancelAddingLightbox">
          取消
        </el-button>
        <el-button
          type="primary"
          :loading="isAddLoading"
          @click="saveAddingForm">
          確定
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as apis from '@/apis/index'
import { mapActions, mapState, mapGetters } from 'vuex'
import cityOptions from '@/const/cityOptions.js'
import { IUnitEditRequest, IUnitEditResponse } from '@/interfaces/apiInterfaces/index'

export default {
  props: {
  },
  data () {
    return {
      cityOptions,
      // tableData: [],
      acceptFileTypes: '.xls,.xlsx',
      isEditLoading: false,
      isDeleteLoading: false,
      isImportLoading: false,
      isAddLoading: false,
      tableToolbarSelect: '',
      editingManagerOptions: [],
      pageSize: 10,
      currentPage: 1,
      editingForm: {
        unit: '',
        new_unit: '',
        managers: [],
        contact:'',
        phone: '',
        email: '',
        county: '',
        address: '',
        account_limit: 0,
        // description: ''
      },
      addingForm: {
        unit: '',
        managers: [],
        contact:'',
        phone: '',
        email: '',
        county: '',
        address: '',
        account_limit: 0,
        // description: ''
      },
      importingForm: {
        import: null,
        _fileList: []
      },
      editingRules: {
        new_unit: [
          { required: true, message: '必填', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '必填', trigger: 'blur' }
        ],
        managers: [
          { required: true, message: '必填', trigger: 'change' }
        ],
        contact: [
          { required: true, message: '必填', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '必填', trigger: 'blur' }
        ],
        email: [
          { required: true, message: '必填', trigger: 'blur' }
        ],
        account_limit: [
          { required: true, message: '必填', trigger: 'blur' }
        ]
      },
      addingRules: {
        unit: [
          { required: true, message: '必填', trigger: 'blur' }
        ],
        address: [
          { required: true, message: '必填', trigger: 'blur' }
        ],
        managers: [
          { required: true, message: '必填', trigger: 'change' }
        ],
        contact: [
          { required: true, message: '必填', trigger: 'blur' }
        ],
        phone: [
          { required: true, message: '必填', trigger: 'blur' }
        ],
        email: [
          { required: true, message: '必填', trigger: 'blur' }
        ],
        account_limit: [
          { required: true, message: '必填', trigger: 'blur' }
        ]
      },
      importingRules: {
        import: [
          { required: true, message: '尚未選擇上傳文件', trigger: 'change' }
        ]
      },
      // unitUserOptions: [], // 單位使用者選單
      dialogAddSingle: false,
      dialogAddMultiple: false,
      editingIndex: -1, // 編輯中的資料索引（非編輯中則為-1）
    }
  },
  computed: {
    ...mapGetters([
      'getterActivedUsers',
      'getterUnitCityOptions'
    ]),
    ...mapState([
      'userInfo',
      'users',
      'units'
    ]),
    tableData () {
      return this.units || []
    },
    filteredTableData () {
      if (this.tableToolbarSelect) {
        return this.tableData.filter(d => {
          return d.county === this.tableToolbarSelect
        })
      } else {
        return this.tableData
      }
    },
    currentTableData () {
      // 分頁
      let startNo = (this.currentPage - 1) * this.pageSize
      return this.filteredTableData.slice(startNo, startNo + this.pageSize)
    },
    tableToolbarSelectOptions () {
      const defaultOptions = [{
        label: '所有縣市',
        value: ''
      }]
      return defaultOptions.concat(this.getterUnitCityOptions)
    },
    addingManagerOptions () {
      return this.getterActivedUsers
        .filter(d => {
          if (d.role === '最高管理者' || d.role === '單位管理者') {
            return true
          }
          return false
        })
        .map(d => {
          return {
            label: d.name,
            value: `${d.name},${d.email}`,
            data: {
              name: d.name,
              email: d.email
            }
          }
        })
    }
  },
  methods: {
    ...mapActions([
      'actionInitData'
    ]),
    showManagersName (managers) {
      if (managers && managers.length) {
        return managers.map(d => d.name).join(',')
      } else {
        return '-'
      }
    },
    cancelEditBlock () {
      // this.editingForm = {}
      this.editingIndex = -1
    },
    openEditBlock (index) {
      // 表單資料
      let editingForm = JSON.parse(JSON.stringify(this.currentTableData[index]))
      editingForm.new_unit = editingForm.unit
      editingForm.managers = editingForm.managers.map(d => `${d.name},${d.email}`)
      this.editingForm = editingForm

      // 管理者選單
      this.editingManagerOptions = this.getterActivedUsers
        .filter(d => {
          if (d.unit === editingForm.unit) {
            if (d.role === '最高管理者' || d.role === '單位管理者') {
              return true
            }
          }
          return false
        })
        .map(d => {
          return {
            label: d.name,
            value: `${d.name},${d.email}`,
            data: {
              name: d.name,
              email: d.email
            }
          }
        })

      // 展開的表單編號
      this.editingIndex = index
    },
    isOpenEditBlock (index) {
      if (index === this.editingIndex) {
        return true
      } else {
        return false
      }
    },
    saveEditingItem (index) {
      this.$refs.editingForm.validate(async (valid) => {
        if (!valid) {
          return
        }
        let managers = this.editingForm.managers
          .map(d => {
            let arr = d.split(',')
            return {
              name: arr[0],
              email: arr[1]
            }
          })
        let searchRequest = {
          unit: this.editingForm.unit,
          managers: managers,
          contact: this.editingForm.contact,
          phone: this.editingForm.phone,
          email: this.editingForm.email,
          county: this.editingForm.county,
          address: this.editingForm.address,
          account_limit: this.editingForm.account_limit,
          // description: this.editingForm.descriptions
        }
        if (this.editingForm.new_unit != this.editingForm.unit) {
          searchRequest.new_unit = this.editingForm.new_unit
        }

        this.isEditLoading = true
        apis.unitEdit(searchRequest)
          .then(function (data){

            this.resetForm()
          }).catch(e => {
            this.isEditLoading = false
          })
      })
    },
    deleteItem (index) {
      this.$confirm('此操作將永久刪除該單位，是否繼續？', '提示', {
        confirmButtonText: '確定刪除',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(data => {
        this.isDeleteLoading = true
        apis.unitDelete({
          unit: this.currentTableData[index].unit
        }).then(d => {
          this.resetForm()
        }).catch(e => {
          this.isDeleteLoading = false
        })
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消刪除'
        // })
      })
    },
    cancelAddingLightbox () {
      this.dialogAddSingle = false
    },
    saveAddingForm () {
      this.$refs.addingForm.validate(async (valid) => {
        if (!valid) {
          return
        }
        let managers = this.addingForm.managers
          .map(d => {
            let arr = d.split(',')
            return {
              name: arr[0],
              email: arr[1]
            }
          })

        this.isAddLoading = true
        apis.unitAdd({
          unit: this.addingForm.unit,
          managers,
          contact: this.addingForm.contact,
          phone: this.addingForm.phone,
          email: this.addingForm.email,
          county: this.addingForm.county,
          address: this.addingForm.address,
          account_limit: this.addingForm.account_limit,
          // description: this.addingForm.descriptions
        })
          .then(data => {
            this.resetForm()
          }).catch(e => {
            this.isAddLoading = false
          })
      })
    },
    cancelImportingLightbox () {
      this.dialogAddMultiple = false
    },
    beforeUpload (file) {
      // const isLt2M = file.size / 1024 / 1024 < 10;
      // if (!isLt2M) {
      //   this.$message.error('檔案大小不可超過 10MB!');
      // }
      if (1 === 1) {
        // console.log('file')
        // console.log(file)
        this.$set(this.importingForm, 'import', file)
        this.$set(this.importingForm, '_fileList', [{ name: file.name }])
      } else {
        this.$set(this.importingForm, 'import', null)
        this.$set(this.importingForm, '_fileList', [])
        // this.$message.error('檔案大小不可超過 10MB!');
      }
      this.$refs.importingForm.validate()
    },
    handleRemove () {
      this.$set(this.importingForm, 'import', null)
      this.$set(this.importingForm, '_fileList', [])
      this.$refs.importingForm.validate()
    },
    saveImportingForm () {
      this.$refs.importingForm.validate((valid) => {
        if (!valid) {
          return
        }
        // formData
        let formData = new FormData()
        formData.append('import', this.importingForm.import)
        this.isImportLoading = true
        apis.unitImport(formData)
          .then(data => {
            this.resetForm()
          }).catch(e => {
            this.isImportLoading = false
          })
      })
    },
    changePage () {

    },
    async resetForm () {
      // 重新取得資料
      await this.actionInitData()
      // 重設畫面
      this.isEditLoading = false
      this.isDeleteLoading = false
      this.isImportLoading = false
      this.isAddLoading = false
      this.dialogAddSingle = false
      this.dialogAddMultiple = false
      this.editingIndex = -1
    }
  },
  created () {
    // apis.unitsGet({
    //   'company_sno': this.userInfo.company_sno
    // })
    //   .then(d => {
    //     this.tableData = d.payload || []
    //   })
  },
  watch: {
    currentTableData () {
      this.editingIndex = -1
    }
  }
}
</script>

<style>
</style>
