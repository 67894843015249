<template>
  <div>
    <div class="table-set">
      <div class="table-toolbar">
        <div class="toolbar-left">
          <el-select v-model="tableToolbarUnitSelect" placeholder="請選擇" size="mini">
            <el-option
              v-for="item in inactivedUnitOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="toolbar-right">
          <!-- <el-button type="primary" size="mini" @click="dialogAddMultiple = true"><i class="fas fa-plus"></i>批次匯入</el-button>
          <el-button type="primary" size="mini" @click="dialogAddSingle = true"><i class="fas fa-plus"></i>手動匯入</el-button> -->
        </div>
      </div>
      <el-table
        :data="currentTableData"
        empty-text="目前暫無新使用者提出申請喔 :)"
        highlight-current-row
        stripe>
        <el-table-column
          type="expand">
          <template slot-scope="scope">
            <!-- 顯示資料 -->
            <div
              class="table table--fixed table--light"
              v-if="isOpenEditBlock(scope.$index)==false">
              <div class="table-row">
                <div class="table-cell label">使用者姓名：</div>
                <div class="table-cell value">{{scope.row.name}}</div>
              </div>
              <div class="table-row">
                <div class="table-cell label">帳戶角色：</div>
                <div class="table-cell value">{{scope.row.role}}</div>
              </div>
              <div class="table-row">
                <div class="table-cell label">email：</div>
                <div class="table-cell value">{{scope.row.email}}</div>
              </div>
              <div class="table-row">
                <div class="table-cell label">單位名稱：</div>
                <div class="table-cell value">{{scope.row.unit}}</div>
              </div>
              <div class="table-row">
                <div class="table-cell label">帳戶申請時間：</div>
                <div class="table-cell value">{{scope.row.regist_time}}</div>
              </div>
            </div>
            <!-- 編輯資料 -->
            <el-form
              ref="editingForm"
              label-width="160px"
              label-position="left"
              v-if="isOpenEditBlock(scope.$index)"
              :model="editingForm">
              <el-form-item label="使用者姓名">
                {{editingForm.name}}
                <!-- <el-input placeholder="請輸入" v-model="editingForm.name"></el-input> -->
              </el-form-item>
              <el-form-item label="帳戶角色">
                <el-select v-model="editingForm.role" placeholder="請選擇" style="width:100%">
                  <el-option
                    v-for="item in getterRoleOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
                <!-- {{editingForm.role}} -->
              </el-form-item>
              <el-form-item label="email">
                {{editingForm.email}}
                <!-- <el-input placeholder="請輸入" v-model="editingForm.email"></el-input> -->
              </el-form-item>
              <el-form-item label="單位名稱">
                {{editingForm.unit}}
                <!-- <el-select v-model="editingForm.unit" placeholder="請選擇" style="width:100%">
                  <el-option
                    v-for="item in getterUnitOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select> -->
              </el-form-item>
              <el-form-item label="帳戶申請時間">
                {{scope.row.regist_time}}
              </el-form-item>
              <el-form-item label="帳號啟用設定">
                <el-switch
                  active-text="啟用"
                  inactive-text="未啟用"
                  active-value="啟用"
                  inactive-value="註冊"
                  :disabled="isStatusSwitchDisabled"
                  v-model="editingForm.status">
                </el-switch>
              </el-form-item>
            </el-form>
            <div class="table-toolbar toolbar-bottom">
              <div class="toolbar-left" v-if="isOpenEditBlock(scope.$index)==false">
                <el-button
                  type="primary"
                  size="mini"
                  @click="openEditBlock(scope.$index)">
                  <font-awesome-icon :icon="['fas', 'pen']"/>編輯
                </el-button>
              </div>
              <div class="toolbar-left" v-if="isOpenEditBlock(scope.$index)">
                <el-button
                  type="primary"
                  size="mini"
                  :disabled="editingForm.status==='註冊'"
                  :loading="isEditLoading"
                  @click="saveEditingItem(scope.$index)">
                  儲存
                </el-button>
                <el-button
                  type="default"
                  size="mini"
                  @click="cancelEditBlock">
                  取消
                </el-button>
                <el-button
                  type="danger"
                  size="mini"
                  :loading="isDeleteLoading"
                  @click="deleteItem(scope.$index)">
                  <i class="fas fa-trash-alt"></i>刪除申請
                </el-button>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="序號"
          type="index"
          align="center"
          width="56">
        </el-table-column>
        <el-table-column
          label="姓名"
          prop="name"
          width="160">
        </el-table-column>
        <el-table-column
          label="所屬單位"
          prop="unit"
          width="160">
        </el-table-column>
        <el-table-column
          label="使用者身份"
          prop="role"
          width="160">
        </el-table-column>
        <el-table-column
          label="使用者電子郵件"
          prop="email">
        </el-table-column>
      </el-table>
    </div>
    <div class="align-right">
      <el-pagination
        layout="prev, pager, next"
        :total="filteredTableData.length"
        :page-size="pageSize"
        :current-page.sync="currentPage">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import * as apis from '@/apis/index.ts'
import { mapActions, mapState, mapGetters } from 'vuex'

export default {
  props: {
    // tableData: {
    //   type: Array,
    //   default () {
    //     return []
    //   }
    // }
  },
  data () {
    return {
      // tableData: [],
      isEditLoading: false,
      isDeleteLoading: false,
      tableToolbarUnitSelect: '',
      pageSize: 10,
      currentPage: 1,
      originRole: '',
      editingForm: {
        'role': '',
        'email': '',
      },
      editingIndex: -1, // 編輯中的資料索引（非編輯中則為-1）
    }
  },
  computed: {
    ...mapGetters([
      'getterAppliedUsers',
      'getterUnitOptions',
      'getterRoleOptions',
      'getterLimitB7',
      'getterLimitB8',
      'getterLimitB9',
    ]),
    ...mapState([
      'userInfo'
    ]),
    isStatusSwitchDisabled () {
      if (this.getterLimitB9 === false) {
        return true
      } else {
        return false
      }
    },
    // 編輯使用者角色選單
    // editRoleOptions () {
    //   let addRoleOptions = this.getterRoleOptions.map(d => d)
    //   // 無「設定最高管理者」權限
    //   if (this.getterLimitB7 === false) {
    //     addRoleOptions.map(d => {
    //       if (d === '最高管理者') {
    //         d.disabled = true
    //       }
    //       return d
    //     })
    //   }
    //   // 無「設定單位管理者」權限
    //   if (this.getterLimitB8 === false) {
    //     addRoleOptions.map(d => {
    //       if (d === '單位管理者') {
    //         d.disabled = true
    //       }
    //       return d
    //     })
    //   }
    //   return addRoleOptions
    // },
    tableData () {
      return this.getterAppliedUsers
    },
    filteredTableData () {
      if (!this.tableToolbarUnitSelect) {
        return this.tableData
      }
      return this.tableData.filter(d => {
        if (this.tableToolbarUnitSelect && d.unit === this.tableToolbarUnitSelect) {
          return true
        }
        return false
      })
    },
    currentTableData () {
      // 分頁
      let startNo = (this.currentPage - 1) * this.pageSize
      return this.filteredTableData.slice(startNo, startNo + this.pageSize)
    },
    inactivedUnitOptions () {
      const defaultOptions = [{
        label: '所有使用者身份',
        value: ''
      }]
      let options = []
      this.getterAppliedUsers.forEach(data => {
        if (options.some(d => d.label == data.unit) == false) {
          options.push({
            label: data.unit,
            value: data.unit
          })
        }
      })
      return defaultOptions.concat(options)
    }
  },
  methods: {
    ...mapActions([
      'actionInitData'
    ]),
    // tableToolbarRoleOptions () {
    //   const defaultOptions = [{
    //     label: '所有使用者身份',
    //     value: ''
    //   }]
    //   return defaultOptions.concat(this.getterRoleOptions)
    // },
    tableToolbarUnitOptions () {
      const defaultOptions = [{
        label: '所有使用者所屬單位',
        value: ''
      }]
      return defaultOptions.concat(this.getterUnitOptions)
    },
    cancelEditBlock () {
      // this.editingForm = {}
      this.editingIndex = -1
    },
    openEditBlock (index) {
      // 表單資料
      this.editingForm = JSON.parse(JSON.stringify(this.currentTableData[index]))
      this.originRole = this.editingForm.role
      // 展開的表單編號
      this.editingIndex = index
    },
    isOpenEditBlock (index) {
      if (index === this.editingIndex) {
        return true
      } else {
        return false
      }
    },
    async saveEditingItem (item) {
      let editEmail = this.editingForm.email
      let editRole = this.editingForm.role

      // 啟用
      this.isEditLoading = true
      const promiseUserActive = await apis.userActive({
        email: this.editingForm.email
      }).then(d => {
          return true
        })
        .catch(e => {
          this.isEditLoading = false
          return false
        })

      if (promiseUserActive == false) {
        return
      }

      // 如果角色有變更，送出修改
      if (editRole !== this.originRole) {
        apis.userEdit({
          email: editEmail,
          role: editRole
        }).then(d => {
          this.resetForm()
        }).catch(e => {
          this.isEditLoading = false
        })
      } else {
        this.resetForm()
      }
    },
    deleteItem (index) {
      this.$confirm('此操作將刪除該申請，是否繼續？', '提示', {
        confirmButtonText: '確定刪除',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(data => {
        this.isDeleteLoading = false
        this.cancelEditBlock()
        apis.userDelete({
          email: this.editingForm.email
        }).then(d => {
          this.resetForm()
        }).catch(e => {
          this.isDeleteLoading = false
        })
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '已取消刪除'
        // })
      })
    },
    cancelAddingLightbox () {
      this.dialogAddSingle = false
    },
    cancelImportingLightbox () {
      this.dialogAddMultiple = false
    },
    async resetForm () {
      // 重新取得資料
      await this.actionInitData()
      // 重設畫面
      this.isEditLoading = false,
      this.isDeleteLoading = false,
      this.editingIndex = -1
    }
  },
  created () {
    // apis.userSignupListGet({
    //   'company_sno': this.userInfo.company_sno
    // })
    //   .then(d => {
    //     this.tableData = d.payload || []
    //   })
  },
  watch: {
    currentTableData () {
      this.editingIndex = -1
    }
  }
}
</script>

<style>
</style>
